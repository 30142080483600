import { Component } from '@angular/core';
import { AppStore } from './store/app.store';
import { PersistenceService } from './shared/services/persistence.service';
import { tap } from 'rxjs';
import { SystemUserService } from './shared/services/system-user.service';
import { AdministratorService } from './administrators/services/administrator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public currentLng = 'rtl';
  title = 'handle-web-app';

  constructor(
    private appStore: AppStore,
    private _systemUserService: SystemUserService,
    private persistenceService: PersistenceService,
    private administratorService: AdministratorService
  ) { }

  ngOnInit(): void {
    this._checkAuth();

    this.appStore.isExit$.pipe(
      tap((isExit) => {
        if (isExit) {
          this._systemUserService.logout();
          this.appStore.setIsExit(false);
        }
      })
    ).subscribe();
  }

  public switchLng(): void {
    this.currentLng = 'ltr';
  }

  private _checkAuth(): void {
    const currentUser = this.persistenceService.get('user');
    if (currentUser) {
      this.appStore.setUser(currentUser);
    } else {
      this._systemUserService.logout();
      this.appStore.setIsExit(false);
    }
  }
}
