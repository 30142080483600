import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { SidebarComponent } from "./sidebar.component";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { TokenModule } from '../../modules/token-module/token.module';

@NgModule({
  declarations: [
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TokenModule,
  ],
  exports: [
    SidebarComponent
  ]
})
export class SidebarModule { }
