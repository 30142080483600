<div class="wrapper">
  <div class="left">
    <div class="header">
      <div class="col">
        <h3 class="title">Terms</h3>
        <a class="text" href="https://app.gigzy.net/public/terms" target="blank"
          >Terms</a
        >
      </div>
      <div class="col">
        <h3 class="title">support@gigzy.net</h3>
        <p class="text">Contact us, if you have an question!</p>
      </div>
    </div>
  </div>
  <div class="right">
    <a href="https://apps.apple.com/ru/app/gigzy/id6443645741" target="blank">
      <img
        class="store_svg"
        src="../../../../assets/svg/app-store.svg"
        alt="Gigzy application on App Store"
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.gigzy.gigzyApp"
      target="blank"
    >
      <img
        class="store_svg"
        src="./assets/svg/google-store.svg"
        alt="Gigzy application on Google Play"
    /></a>
  </div>
</div>
