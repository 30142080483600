<div class="sidebar">
  <a routerLink="/" class="logo">
    <img
      src="../../../../assets/svg/logo.svg"
      alt="Gigzy Admin logotype"
      class="logo_img"
    />
    <span class="text">Gigzy Admin</span>
  </a>
  <nav class="navbar">
    <ul class="list">
      <li
        class="nav_item"
        *ngFor="let item of sidebarNavList"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: !!item.first }"
      >
        <a [routerLink]="[item.route]" class="nav_link">
          <div class="icon_box">
            <mat-icon class="icon">{{ item.icon }}</mat-icon>
          </div>
          <span class="nav_text">{{ item.text }}</span>
        </a>
      </li>
    </ul>

    <!-- <div class="divider">
      <span></span>
    </div>

    <ul class="list private">
      <li class="nav_item" routerLinkActive="active" *ngFor="let item of sidebarAppList">
        <a [routerLink]="[item.route]" class="nav_link">
          <div class="icon_box">
            <mat-icon class="icon">{{item.icon}}</mat-icon>
          </div>
          <span class="nav_text">{{ item.text }}</span>
        </a>
      </li>
    </ul> -->
  </nav>
  <button class="exit nav_item" type="button" (click)="logout()">
    <div class="icon_box">
      <mat-icon class="icon">exit_to_app</mat-icon>
    </div>
    <span class="nav_text">Exit</span>
  </button>
</div>
