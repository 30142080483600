import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header.component";
import { TokenModule } from '../../modules/token-module/token.module';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TokenModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
