import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAuthGuard } from './shared/services/checkAuth.guard';
import { AdminGuard } from './shared/services/admin.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [CheckAuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'worker-management',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./worker-management/worker-management.module').then(
        (m) => m.WorkerManagementModule
      ),
  },
  {
    path: 'employer-management',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./employer-management/employer-management.module').then(
        (m) => m.EmployerManagementModule
      ),
  },
  {
    path: 'jobs',
    canActivate: [AdminGuard],
    loadChildren: () => import('./jobs/jobs.module').then((m) => m.JobsModule),
  },
  {
    path: 'sessions-list',
    canActivate: [AdminGuard],
    loadChildren: () => import('./session-list/user-sessions.module').then((m) => m.UserSessionsModule),
  },
  {
    path: 'administrators',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./administrators/administrators.module').then(
        (m) => m.AdministratorsModule
      ),
  },
  {
    path: 'work-types',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./work-types/work-types.module').then((m) => m.WorkTypesModule),
  },
  {
    path: 'landing-requests',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./landing-requests/landing-requests.module').then(
        (m) => m.LandingRequestsModule
      ),
  },
  {
    path: 'removed-jobs',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./removed-jobs/removed-jobs.module').then(
        (m) => m.RemovedJobsModule
      ),
  },
  {
    path: 'support',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./support/support.module').then((m) => m.SupportModule),
  },
  {
    path: 'profile-fill',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./profile-fill/profile-fill.module').then(
        (m) => m.ProfileFillModule
      ),
  },
  {
    path: 'updates',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./updates/updates.module').then((m) => m.UpdatesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
