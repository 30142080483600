import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStore } from './store/app.store';
import { SystemUserService } from './shared/services/system-user.service';
import { PersistenceService } from './shared/services/persistence.service';
import { TokenInterceptor } from './shared/services/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import { SidebarModule } from './shared/components/sidebar/sidebar.module';

import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenModule } from './shared/modules/token-module/token.module';
import { HeaderMobileModule } from './shared/components/header-mobile/header-mobile.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const maskConfigFunction: () => Partial<IConfig> = () => ({
  validation: false,
});
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
     MatNativeDateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SidebarModule,
    HeaderMobileModule,
    HeaderModule,
    FooterModule,
    TokenModule,
    HttpClientModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppStore,
    SystemUserService,
    PersistenceService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   multi: true,
    //   useClass: TokenInterceptor,
    // },
    { provide: MAT_DATE_LOCALE, useValue: 'he-HE' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'DD.MM.YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'DD.MM.YYYY',
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
