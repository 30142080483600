import { Observable, of, switchMap } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AppStore } from '../../store/app.store';
import { UserInterface } from '../interfaces/user.interface';
import { UserRoleEnum } from '../interfaces/enums/user-role.enum';

@Injectable({
  providedIn: 'root',
})
export class CheckAuthGuard implements CanActivate, CanActivateChild {
  constructor(private appStore: AppStore, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.appStore.user$.pipe(
      switchMap((user: UserInterface) => {
        // If user exist, then return false, else open Login page
        if (user && user.role === UserRoleEnum.ADMIN) {
          this.router.navigate(['/worker-management']);
          return of(false);
        }

        return of(true);
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
