<div class="header">
  <h1 class="title" [class.active]="(header$ | async)!.breadcrumbs">
    {{ (header$ | async)!.headerTitle }}
  </h1>
  <div class="breadcrumbs" *ngIf="(header$ | async)!.breadcrumbs">
    <ng-container *ngFor="let breadcrumb of (header$ | async)!.breadcrumbs">
      <span class="divider"></span>
      <span class="route">{{ breadcrumb.title }}</span>
    </ng-container>
  </div>
</div>