import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs';
import { AppStore } from '../../../store/app.store';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {
  header$ = this.appStore.header$;

  @Output()
  onToggleMenu = new EventEmitter();

  constructor(private appStore: AppStore) {}

  ngOnInit(): void {}

  public logout(): void {
    this.appStore.setIsExit(true);
  }

  public openMenu() {
    this.onToggleMenu.emit('true');
  }
}
