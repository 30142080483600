import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { Subscription} from "rxjs";

import {AppStore} from "../../../store/app.store";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public header$ = this.appStore.header$
  public breadcrumbs = false
  private _subscription$: Subscription = new Subscription();

  constructor(private router: Router, private appStore: AppStore) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._subscription$.unsubscribe()
  }
}
