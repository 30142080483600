import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import { AppStore } from '../../store/app.store'
import { PersistenceService } from './persistence.service'

@Injectable()
export class SystemUserService {
  constructor(
    private persistenceService: PersistenceService,
    private appStore: AppStore,
    private router: Router) {
  }

  getToken(): string {
    return this.persistenceService.get('user').accessToken;
  }

  getRefreshToken(): string {
    return this.persistenceService.get('user').refreshToken;
  }

  logout(b: boolean = true) {
    this.appStore.setUser({
      role: null,
      name: '',
      accessToken: '',
      refreshToken: ''
    });
    localStorage.clear();
    if (b) this.router.navigate(['/login'])
  }
}
