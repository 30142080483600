import { UserInterface } from "../shared/interfaces/user.interface";
import { HeaderInterface } from "../shared/interfaces/header.interaface";

export interface AppState {
  user: UserInterface;
  isExit: boolean;
  header: HeaderInterface
}

export const DEFAULT_STATE: AppState = {
  user: {
    role: null,
    name: '',
    accessToken: '',
    refreshToken: ''
  },
  isExit: false,
  header: {
    headerTitle: '',
    breadcrumbs: []
  },
}
