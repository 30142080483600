import { Component, OnInit } from '@angular/core';
import { AppStore } from '../../../store/app.store';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  sidebarNavList = [
    {
      text: 'Giggers',
      route: '/worker-management/worker-list',
      icon: 'people',
      first: true,
    },
    {
      text: 'Employer registration applications',
      route: '/employer-management/employer-request-list',
      icon: 'how_to_reg',
      first: false,
    },
    {
      text: 'Employers',
      route: '/employer-management/employer-list',
      icon: 'face',
      first: false,
    },
    { text: 'Gigz', route: '/jobs', icon: 'work', first: false },
    {
      text: 'Administrators',
      route: '/administrators/administrator-list',
      icon: 'work',
      first: false,
    },
    { text: 'Gigz Types', route: '/work-types', icon: 'work', first: false },
    {
      text: 'Landing Requests',
      route: '/landing-requests/landing-requests-list',
      icon: 'assignment',
      first: false,
    },
    {
      text: 'Removed Gigz',
      route: '/removed-jobs/removed-jobs-list',
      icon: 'delete',
      first: false,
    },
    // {
    //   text: 'Sessions',
    //   route: '/sessions-list',
    //   icon: 'face',
    //   first: false,
    // },
    {
      text: 'Pofile Steps',
      route: '/profile-fill',
      icon: 'face',
      first: false,
    },
    {
      text: 'Support',
      route: '/support',
      icon: 'contact_support',
      first: false,
    },
    {
      text: 'Updates',
      route: '/updates',
      icon: 'updates',
      first: false,
    },
  ];

  // sidebarAppList = [
  //   //  { text: 'Settings', route: '/settings', icon: 'settings' },
  // ];

  constructor(private appStore: AppStore) {}

  ngOnInit(): void {}

  public logout(): void {
    this.appStore.setIsExit(true);
  }
}
