<mat-toolbar class="header__toolbar">
  <a routerLink="/" class="header__link">
    <div class="header__logo-container">
      <img
        src="../../../../assets/svg/logo.svg"
        alt="Logotype"
        class="header__logo-img"
      />
      <span class="header__logo-title">Gigzy Admin</span>
    </div>
  </a>
  <span class="header__title">{{ (header$ | async)?.headerTitle }}</span>
  <button (click)="openMenu()" mat-icon-button>
    <mat-icon class="header__icon">menu</mat-icon>
  </button>
</mat-toolbar>
