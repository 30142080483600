import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMobileComponent } from './header-mobile.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { TokenModule } from '../../modules/token-module/token.module';
import {MatToolbarModule} from '@angular/material/toolbar';

@NgModule({
  declarations: [HeaderMobileComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatToolbarModule,
    TokenModule,
    MatSidenavModule,
  ],
  exports: [HeaderMobileComponent],
})
export class HeaderMobileModule {}
