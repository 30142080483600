import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { AdministratorInterface } from '../interfaces/administrator.interface';


@Injectable({
  providedIn: 'root'
})
export class AdministratorService {
  constructor(private http: HttpClient) { }

  getAll(): Observable<AdministratorInterface[]> {
    return this.http.get<AdministratorInterface[]>(`${environment.apiUrl}/administrators`)
  }

  getById(id: string): Observable<AdministratorInterface> {
    return this.http.get<AdministratorInterface>(`${environment.apiUrl}/administrators/${id}`)
  }

  remove(id: string): Observable<AdministratorInterface> {
    return this.http.delete<AdministratorInterface>(`${environment.apiUrl}/administrators/${id}`)
  }

  update(data: AdministratorInterface): Observable<AdministratorInterface> {
    return this.http.put<AdministratorInterface>(`${environment.apiUrl}/administrators`, data)
  }

  create(data: AdministratorInterface): Observable<AdministratorInterface> {
    return this.http.post<AdministratorInterface>(`${environment.apiUrl}/administrators/register`, data)
  }

}
